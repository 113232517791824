import {
    Inject,
    Injectable,
    Signal,
    signal,
    WritableSignal,
} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LOCALSTORAGE } from './local-storage.service';

const FEATURE_FLAGS = {
    SELF_BOOKING: false,
};

export type FeatureFlag = keyof typeof FEATURE_FLAGS;

export const FeatureFlags: Record<FeatureFlag, FeatureFlag> = Object.assign(
    {},
    ...Object.keys(FEATURE_FLAGS).map(key => ({ [key]: key }))
);

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService {
    private featureFlagSignals: {
        [key in FeatureFlag]: WritableSignal<boolean>;
    } = Object.assign(
        {},
        ...Object.entries(FEATURE_FLAGS).map(([key, value]) => ({
            [key]: signal(value),
        }))
    );

    constructor(@Inject(LOCALSTORAGE) private localStorage: Storage) {
        Object.entries(this.featureFlagSignals).forEach(([key, signal]) => {
            if (this.localStorage.getItem(`feature_flag_${key}`)) {
                signal.set(
                    this.localStorage.getItem(`feature_flag_${key}`) === 'true'
                );
            }
        });
    }

    getFeatureFlag(flag: FeatureFlag): Signal<boolean> {
        return this.featureFlagSignals[flag].asReadonly();
    }

    setFeatureFlag(
        flag: FeatureFlag,
        state: boolean,
        persist: boolean = false
    ): void {
        this.featureFlagSignals[flag].set(state);
        if (persist) {
            this.localStorage.setItem(`feature_flag_${flag}`, state.toString());
        }
    }
}

interface FeatureFlagResolverData {
    featureFlag: FeatureFlag;
    value?: boolean;
    persist?: boolean;
}

@Injectable({ providedIn: 'root' })
export class FeatureFlagResolver {
    constructor(private featureFlagService: FeatureFlagService) {}

    resolve(route: ActivatedRouteSnapshot) {
        if (!route.data['featureFlags']) {
            return;
        }
        route.data['featureFlags'].forEach(
            ({ featureFlag, value, persist }: FeatureFlagResolverData) => {
                if (!value) {
                    this.featureFlagService.setFeatureFlag(
                        featureFlag,
                        FEATURE_FLAGS[featureFlag]
                    );
                } else {
                    this.featureFlagService.setFeatureFlag(
                        featureFlag,
                        value,
                        persist
                    );
                }
            }
        );
    }
}
